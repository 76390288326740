/**
 * Defaults
 */

html {
  box-sizing: border-box;
  font-size: 16px;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body, h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  padding: 0;
  font-weight: normal;
}


img {
  max-width: 100%;
  height: auto;
}


/**
 * Layout
 */

html, body {
    position: relative;
    height: 100%;
}

body {
    display: flex;
    flex-flow: column nowrap;
}

body > header {
    flex: 0 0 10em;
    height: 10em;
}

body > header nav ul {
    padding: 0;
    margin : 0;
    list-style-type: none;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: space-around;
}

body > main {
    flex: 1 1 auto;
    margin: 3em 1em 1em 1em;
}

body > footer {
    flex: 0 0 5em;
    height: 5em;
}

@media screen and (min-width: 800px){
    body > main {
        margin: 3em 5% 1em 5%;
    }
    body > header nav ul li {
        padding: 0.5em 1em;
    }
    body > header nav ul li a{
        margin: 0 1em;
    }
}
@media screen and (min-width: 1024px){
    body > main {
        margin: 3em 10% 1em 10%;
    }
}
@media screen and (min-width : 1280px){
    body > main {
        margin: 3em 20% 1em 20%;
    }
}


/**
 * Theming 
 */

body {
    background-color: #252525;
    color: #e0e0e0;
    font-family: 'Roboto Mono', monospace;
}

h1, h2, h3, header {
    font-family: 'Love Ya Like A Sister', serif;
}

body > header h1 {
    text-align: center;
    font-size: 3.5em;
    padding: 0.5em;
}

header nav ul li {
    padding: 0.5em 1em;
}
header nav ul li a {
    font-size: 1.25em;
    position: relative;
    display: inline-block;
}

a {
    color: #fff;
    text-decoration: none;
    display: inline-block;
}
a:after{
    content : '';
    display : block;
    width: 0;
    height: 2px;
    background-color : transparent;
    transition: background-color 400ms ease-out,
                width 400ms ease-out;

}
a:active:after,
a:focus:after,
a:hover:after {
    width: 100%;
    background-color: #e0e0e0;
}

main article p {
    margin-bottom: 2em;
}
main article h1 {
    font-size: 1.75em;
}
main article h2 {
    font-size: 1.5em;
}
main article h3 {
    font-size: 1.25em;
}
main article h1,
main article h2,
main article h3{
    margin-bottom : .5em;
}
main article ul {
    list-style-type: none;
}
main article ul li:before {
    content: '~ ';
}

footer {
    text-align: center;
    color: #bbb;
}


/**
 * Welcome page
 */

@keyframes typewritting-16 {
  0% {
     opacity: 1;
     width: 0;
  }
  25% {
     opacity: 1;
     width: 16em;
  }
  100% {
     opacity: 1;
     width: 16em;
  }
}
@keyframes typewritting-15 {
  0% {
     opacity: 1;
     width: 0;
  }
  25% {
     opacity: 1;
     width: 15em;
  }
  100% {
     opacity: 1;
     width: 15em;
  }
}@keyframes typewritting-13 {
  0% {
     opacity: 1;
     width: 0;
  }
  25% {
     opacity: 1;
     width: 13em;
  }
  100% {
     opacity: 1;
     width: 13em;
  }
}
@keyframes typewritting-11 {
  0% {
     opacity: 1;
     width: 0;
  }
  25% {
     opacity: 1;
     width: 11em;
  }
  100% {
     opacity: 1;
     width: 11em;
  }
}
@keyframes typewritting-12 {
  0% {
     opacity: 1;
     width: 0;
  }
  25% {
     opacity: 1;
     width: 12em;
  }
  100% {
     opacity: 1;
     width: 12em;
  }
}
@keyframes typewritting-9 {
  0% {
     opacity: 1;
     width: 0;
  }
  25% {
     opacity: 1;
     width: 9em;
  }
  100% {
     opacity: 1;
     width: 9em;
  }
}
@keyframes blink {
  from, to{
     border-color: transparent;
  }
  50% {
     border-color: white;
  }
}
@keyframes appear {
  0%{
     opacity: 0;
  }
  100% {
     opacity: 1;
  }
}

.welcome ul {
  list-style-type : none;
  position: relative;
  height: 1em;
  padding:0;
  margin:0 auto;
  width: 100%;
}
.welcome ul li:before {
    content: '';
}

.welcome  ul li {
  opacity: 0;
  white-space: nowrap;
  overflow: hidden;
  padding:0;
  margin:0 auto;
  border-right: solid .2em white;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 0;
  font-size: 1.1em;
  letter-spacing: .1em;
}
.tw11{
  animation :
      blink .75s step-end infinite,
      typewritting-11 5s steps(15,end);
}
.tw9{
    animation :
      blink .75s step-end infinite,
      typewritting-9 5s steps(15,end);
}
.tw15{
    animation :
      blink .75s step-end infinite,
      typewritting-15 5s steps(15,end);
}
.tw12{
    animation :
      blink .75s step-end infinite,
      typewritting-12 5s steps(15,end);
}
.tw13{
    animation :
      blink .75s step-end infinite,
      typewritting-13 5s steps(15,end);
}
.tw16{
    animation :
      blink .75s step-end infinite,
      typewritting-16 5s steps(15,end);
}
.welcome ul li:nth-child(1){
   animation-delay: 1;
}
.welcome ul li:nth-child(2){
   animation-delay: 6s ;
}
.welcome ul li:nth-child(3){
  animation-delay: 11s;
}
.welcome ul li:nth-child(4){
  animation-delay: 16s;
}
.welcome ul li:nth-child(5){
  animation-delay: 21s;
}
main ul li:nth-child(6){
  animation-delay: 26s;
}
.welcome h2{
    opacity : 0;
    text-align:center;
    animation : appear 3s ease-out 31s forwards;
}


/**
 * Projects page
 */

.projects-list {
    margin-top: 1em;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items : stretch;
}
.projects-list article {
    flex: 1 1 auto;
    min-width: 200px; 
    max-width: 300px;
    border: solid 2px #333;
    border-radius: 4px;
    position:relative;
    background-color: #333;
    margin: .25em;
}
.projects-list article h1 {
    text-align: center;
    padding: 0.25em 0;
    margin: 0;
    background-color: #252525;
}
.projects-list article > div {
    padding: .5em;
}
.projects-list article > div p {
    margin: .5em 0;
}

.projects-list article > div nav {
    text-align: center;
}
.projects-list article > div nav a {
    margin: 0 .25em;
}
.projects-list article > div a img {
    width: 1.75em;
    height: 1.75em;
    color: white;
}

